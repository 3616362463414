<template>
    <Dialog class="modal" :style="{
        width: '402px',
        borderRadius: '8px',
    }" :visible="visible" @hide="close" @update:visible="onUpdate">
        <template #header>
            <h5 class="m-auto">Editar Serviço Vinculado</h5>
        </template>
        <ItemSelected :item="obj" v-model:inPerson="inPerson" v-model:videoCall="videoCall" v-model:homeCare="homeCare"
            :addressPlaceId="addressPlaceId" @clear="clear" />
        <template #footer>
            <div class="flex justify-content-end align-items-center h-full">
                <Button label="Editar" class="addService-button" @click="onSubmit" />
            </div>
        </template>
    </Dialog>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import ItemSelected from "./ItemSelected";
import profileService from "../../../../../core/services/professionalProfileService";
import { UpdateServiceTypeRequest } from "../../../../../core/grpc/generated/professionalProfile_grpc_web_pb";

export default {
    props: ["visible", "obj", "addressPlaceId", "serviceId"],
    emits: ["update:visible", "save"],
    components: {
        ItemSelected,
    },
    setup(props, { emit }) {
        const searchinput = ref({ name: "", id: "" })
        const inPerson = ref(null)
        const videoCall = ref(null)
        const homeCare = ref(null)
        const close = () => {
            emit("update:visible", false)
            searchinput.value = { name: "", id: "" }
        }
        const onUpdate = val => (!val) && emit("update:visible", val);

        const CloseAll = () => {
            close();
            // emit("closeall", true);
        }

        const clear = () => searchinput.value = { name: "", id: "" }

        const StringToMinutes = (str) => {
            const date = new Date(`Tue Jun 14 2022 ${str}:00 GMT-0300 (Hora padrão de Brasília)`);

            return date.getHours() * 60 + date.getMinutes();
        }

        const EditLinkService = async obj => {
            try {
                const req = new UpdateServiceTypeRequest();
                req.setServiceid(obj.serviceId);
                req.setAddressplaceid(obj.addressPlaceId);
                obj.service_type_places.map(service => {
                    var serviceTypes = new proto.professionalProfileApi.UpdateServiceTypes();
                    serviceTypes.setServicemode(service.serviceMode);
                    serviceTypes.setEstimatedTime(service.estimated_time);
                    serviceTypes.setValue(service.value);
                    serviceTypes.setValuepercentage(service.valuePercentage ? service.valuePercentage : 0);
                    serviceTypes.setStatus(service.status);
                    if (service.serviceMode === 'Homecare') {
                        serviceTypes.setMaxDisplacement(service.max_displacement);
                        serviceTypes.setValuekm(service.valueKm);
                    }
                    req.addServicetypes(serviceTypes);
                })
                const res = await profileService.updateServiceType(req);

                if(res.success){
                    close();
                    emit("save", true);
                }

                console.log(res);
            } catch (error) {
                console.log(error)
            }
        }

        const onSubmit = () => {
            let obj = {
                addressPlaceId: props.addressPlaceId,
                serviceId: props.serviceId,
                service_type_places: [],
            }
            if (inPerson.value) {
                obj.service_type_places.push({
                    serviceMode: "Presencial",
                    estimated_time: StringToMinutes(inPerson.value.duration),
                    value: inPerson.value.valueInput,
                    valuePercentage: inPerson.value.porcent,
                    status: inPerson.value.active ? 'Ativo' : 'Inativo',
                })
            }
            if (videoCall.value) {
                obj.service_type_places.push({
                    serviceMode: "Videochamada",
                    estimated_time: StringToMinutes(videoCall.value.duration),
                    value: videoCall.value.valueInput,
                    valuePercentage: videoCall.value.porcent,
                    status: videoCall.value.active ? 'Ativo' : 'Inativo',
                })
            }
            if (homeCare.value) {
                obj.service_type_places.push({
                    serviceMode: "Homecare",
                    estimated_time: StringToMinutes(homeCare.value.duration),
                    value: homeCare.value.valueInput,
                    valuePercentage: homeCare.value.porcent,
                    valueKm: homeCare.value.km,
                    max_displacement: homeCare.value.raio,
                    status: homeCare.value.active ? 'Ativo' : 'Inativo',
                })
            }
            EditLinkService(obj)
        }

        return {
            searchinput,
            close,
            onUpdate,
            CloseAll,
            clear,
            onSubmit,
            inPerson,
            videoCall,
            homeCare,
        };
    },
};
</script>

<style lang="scss" scoped>
.content {
    margin-top: 1.5rem;
    margin-bottom: -10px;

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin: 8px 0;
        padding: 0;
    }

    hr {
        border-color: #F2F2F2;
        margin: 0;
    }

    .buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 8px;
        margin-top: 24px;

        .btn-cancel {
            background: white;
            border: 1px solid white;
            box-shadow: none;
            color: #FF4500;
        }
    }
}

::v-deep(button) {
    max-width: 104px;
    height: 40px;
}

::v-deep(.p-input-icon-left) {
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;

    input {
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }
}
</style>
