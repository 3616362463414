<template>
    <Dialog 
        class="modal" 
        :style="{
            width: '320px',
            borderRadius: '8px',
        }" 
        :visible="visible" 
        :closable="false"
        @hide="close" 
        @update:visible="onUpdate"
    >
        <template #header>
            <h5 class="m-auto">Excluir local</h5>
        </template>

        <div class="content">
            <p>Tem certeza que deseja excluir esse local?</p>   
            <div class="buttons">
                <Button class="btn-cancel" label="Cancelar" @click="close" />
                <Button label="Excluir" @click="deleteLocation" />
            </div>
        </div>
    </Dialog>
</template>

<script>
import profileService from "../../../../core/services/professionalProfileService";
import { DeletePlaceRequest } from "../../../../core/grpc/generated/professionalProfile_grpc_web_pb";

export default {
    props: ["visible", "id"],
    emits: ["update:visible", "closeall", "successfull", "failedDeleteLocation"],
    setup(props, { emit }) {
        const close = () => emit("update:visible", false)
        const onUpdate = val => (!val) && emit("update:visible", val);

        const CloseAll = () => {
            close();
            emit("update:visible", false);
            // emit("closeall", true);
        }

        const deleteLocation = async () => {
            try {
                const req = new DeletePlaceRequest()
                req.setAddressplaceid(props.id)
                const res = await profileService.deleteLocation(req);
                const { success } = res
                console.log(res);
                if (success) {
                    emit("successfull", true);                
                }else{
                    emit("failedDeleteLocation", res.errormessage);
                }
                close();
            } catch (error) {
                console.log(error);
            }
        }

        return {
            close,
            onUpdate,
            CloseAll,
            deleteLocation,
        };
    },
};
</script>

<style lang="scss" scoped>
.content {
    margin-top: 1.5rem;    
    margin-bottom: -10px;
    p{
        max-width: 200px;
        text-align: center;
        margin: 0 auto;
        color: #828282;
    }
    .buttons{
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 8px;
        margin-top: 24px;
        .btn-cancel{
            background: white;
            border: 1px solid white;
            box-shadow: none;
            color: #FF4500;
        }
    }
}

::v-deep(button) {
    max-width: 104px;
    height: 40px;
}
</style>
