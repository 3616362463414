<template v-if="!isLoading">
  <!-- default: {{valueDefault}}
  valueInput: {{valueInput}}
  switch: {{boolSwitch}} -->
  <div class="flex justify-content-between align-items-center" style="margin-top: 8px" :class="!visibleBarPorcent && 'cursor-not-allowed'">
    <div class="flex align-items-center">
      <div class="tag-color mr-2" style="background: #a2c0d4" v-if="tipo === 'Presencial'" />
      <div class="tag-color mr-2" style="background: #2d313d" v-if="tipo === 'Videochamada'" />
      <div class="tag-color mr-2" style="background: #ff6a33" v-if="tipo === 'Homecare'" />
      <p class="name-type">{{ tipo }}</p>
    </div>
    <!-- TODO: UPDATE ACTIVE -->
    <div 
      v-tooltip.top="{
      value: 'Não é possível habilitar este serviço, ative ou cadastre em serviços oferecidos.', 
      disabled: visibleBarPorcent}"
      :class="!visibleBarPorcent && 'cursor-not-allowed'"
    >
      <InputSwitch 
        v-model="boolSwitch" 
        @input="$emit('update:active', $event)" 
        :disabled="!visibleBarPorcent" 
      />
    </div>
  </div>
  <div class="no-spacing" v-if="boolSwitch">
    <div class="flex col-12 m-0 p-0" style="margin-top: 16px !important; ">
      <div class="flex flex-column col-6 m-0 p-0 pr-1">
        <!-- TODO: Duration -->
        <p class="label">Duração</p>
        <Calendar id="time12" v-model="valueDuration" @blur="$emit('update:duration', $event.target.value)"
          :timeOnly="true" :showTime="true" />
      </div>
      <div class="flex flex-column col-6 input-max m-0 p-0 pl-1">
        <p class="label">Valor</p>
        <InputNumber id="currency-us" v-model="valueInput" @input="changeValue" mode="currency" currency="BRL"
          locale="pt-br" :min="0" />
      </div>
    </div>
    <div class="content" style="margin: 12px 0px; margin-top: 25px;" v-if="visibleBarPorcent">
      <img src="../../../assets/icons/desconto-subtrair.png" alt="" @click="subtrair" v-tooltip.top="'Subtrair'"
        class="pointer" />
      <div class="div-barra">
        <div class="porcent" :style="'transform: translate(' + alinhamento * 1.8 + '%);'">
          {{ porcentagem }} %
        </div>
        <div class="barra"></div>
      </div>
      <img class="pointer" src="../../../assets/icons/desconto-somar.png" alt="" @click="somar"
        v-tooltip.top="'Somar'" />
    </div>
  </div>

  <Divider v-if="tipo !== 'Homecare'" />
  <div class="flex flex-column" style="margin-top: 25px" v-if="tipo === 'Homecare' && boolSwitch">
    <p class="label">Deslocamento referente à <b>Clínica Sodré.</b></p>
    <div class="flex col-12 m-0 p-0">
      <div class="flex flex-column col-6 m-0 p-0 pr-1">
        <p class="label">Valor por Km</p>
        <InputNumber id="currency-us" v-model="valueKm" @input="$emit('update:km', $event.value)" mode="currency"
          currency="BRL" locale="pt-br" />
      </div>
      <div class="flex flex-column col-6 input-max m-0 p-0 pl-1">
        <p class="label">Raio de abrangência</p>
        <InputNumber id="currency-us" v-model="valueRaio" @input="$emit('update:raio', $event.value)" mode="currency"
          currency="BRL" locale="pt-br" />
      </div>
    </div>
  </div>

</template>

<script>
import { onMounted, ref, computed } from "vue";

export default {
  props: ["tipo", "active", "duration", "value", "porcent", "km", "raio"],
  setup(props, { emit }) {
    const isLoading = ref(true);
    const visibleBarPorcent = computed(() => {
      if(valueDefault.value > 0){
        return true;
      }
      return false;
    })
    const maxValue = computed(() => {
      if(valueDefault.value > 0){
        return valueDefault.value * 2;
      }
      return;
    })

    const boolSwitch = ref(props.active);
    const valueDefault = ref();
    const valueInput = ref(props.value);
    const valueKm = ref(props.km);
    const valueRaio = ref(props.raio);
    const valueDuration = ref(props.duration);
    const porcentagem = ref(props.porcent);
    const alinhamento = ref(props.porcent);

    const somar = () => {
      if (valueDefault.value > 0) {
        porcentagem.value += 1;
        if(alinhamento.value + 1 > 100) {
          alinhamento.value = 100;
        }else{
          alinhamento.value += 1;
        }
        valueInput.value = valueDefault.value + (valueDefault.value / 100) * porcentagem.value;
        emit("update:porcent", porcentagem);
        emit("update:value", valueInput);
      }
    };

    const subtrair = (e) => {
      if (valueDefault.value > 0) {
        porcentagem.value -= 1;
        if(alinhamento.value -1 < -100){
          alinhamento.value = -100;
        }else{
          alinhamento.value -= 1;
        }
        valueInput.value =
          valueDefault.value -
          (valueDefault.value / 100) * (porcentagem.value * -1);
        emit("update:porcent", porcentagem);
        emit("update:value", valueInput);
      }
    };

    const changeValue = (e) => {
      let diferenca = 0;
      let porc = 0;

      if(valueDefault.value > 0){
        if (e.value > valueDefault.value) {
          diferenca = e.value - valueDefault.value;
          porc = e.value / (valueDefault.value / 100) - 100;
        } else {
          diferenca = valueDefault.value - e.value;
          porc = e.value / (valueDefault.value / 100) - 100;
        }
        porcentagem.value = parseInt(porc);

        if(parseInt(porc) > 100){
          alinhamento.value = 100;
        }else{
          alinhamento.value = parseInt(porc);
        }
        emit("update:porcent", porcentagem);
      }


      emit("update:value", valueInput);
      valueInput.value = parseInt(e.value);
    };

    const checkContent = () => {
      if(!visibleBarPorcent.value){
        alert('Serviço não habilitado em serviços oferecidos.')
      }
    }

    onMounted(async () => {   
      // console.log(props.active)  
      porcentagem.value = await props.porcent;
      if(props.porcent > 0){
        valueDefault.value = await props.value - ((props.value / (100 + props.porcent)) * props.porcent);
      }else{
        valueDefault.value = await props.value;
      }
      isLoading.value = false;
    });

    return {
      maxValue,
      visibleBarPorcent,
      boolSwitch,
      valueDefault,
      valueInput,
      valueKm,
      valueRaio,
      valueDuration,
      porcentagem,
      alinhamento,
      somar,
      subtrair,
      changeValue,
      checkContent,
    };
  },
};
</script>


<style lang="scss" scoped>
.content {
  display: flex;
  align-items: center;
  width: 100%;
}
.no-spacing{
  padding: 0 !important;
  margin: 0 !important;
}

.div-barra {
  position: relative;
  flex: 1;
  padding: 0 8px;

  .barra {
    background: #f2f2f2;
    border-radius: 4px;
    width: 100%;
    height: 4px;
  }

  .porcent {
    background: white;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #828282;
    width: 63px;
    text-align: center;
    margin: 0 auto;
    z-index: 999 !important;
    padding: 5px;
    position: absolute;
    top: calc(50% - 16px);
    left: 0;
    right: 0;
  }
}

.tag-color {
  width: 4px;
  height: 16px;
  margin-right: 8px;
  border-radius: 2px;
}

.pointer {
  cursor: pointer;
}

.label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #828282;
}

p {
  margin: 0;
}
.name-type{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  // line-height: 24px;
  color: #4F4F4F;
}

::v-deep(input) {
  border-radius: 8px !important;
  height: 40px !important;
  text-align: right !important;
}
</style>