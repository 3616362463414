<template>
    <!-- {{listInatives}} -->
    <div class="service-type flex flex-column flex-wrap" :style="getInative(el.id) && 'opacity: .6'" v-for="el in service"
        v-if="!isLoading && service.length > 0">
        <!-- {{item.id}}<br>
        {{el.id}} -->
        <div class="flex align-items-center justify-content-between flex-wrap">
            <div class="flex flex-wrap gap-164" style="gap: 115px;">
                <div class="flex flex-column gap-4 width-names">
                    <p class="name-service">{{ el.name }}</p>
                    <p class="profission-name">Médico(a)</p>
                </div>
                <div class="flex align-items-center service-mode">
                    <!-- Presencial -->
                    <div class="flex flex-column gap-4 container-presencial">
                        <div class="flex align-items-center gap-4"
                            :class="getStatus(getTypeMode('Presencial', el)?.status)">
                            <div class="box-tag">
                                <div class="tag-color" />
                            </div>
                            <img src="../../../../assets/icons/currency.png" alt=""
                                :class="getStatus(getTypeMode('Presencial', el)?.status) && 'opc-inative'" />
                            {{ transformCurrency(getTypeMode('Presencial', el)?.value) }}
                        </div>
                        <div class="flex align-items-center gap-4"
                            :class="getStatus(getTypeMode('Presencial', el)?.status)">
                            <img class="clock" src="../../../../assets/icons/clock-time.png" alt=""
                                :class="getStatus(getTypeMode('Presencial', el)?.status) && 'opc-inative'" />
                            {{ minutesToHm(getTypeMode('Presencial', el)?.estimatedTime) }} h
                        </div>
                    </div>
                    <!-- Videochamada -->
                    <div class="flex flex-column gap-4 container-videochamada">
                        <div class="flex align-items-center gap-4"
                            :class="getStatus(getTypeMode('Videochamada', el)?.status)">
                            <div class="box-tag">
                                <div class="tag-color" style="background: #333333" />
                            </div>
                            <img src="../../../../assets/icons/currency.png" alt=""
                                :class="getStatus(getTypeMode('Videochamada', el)?.status) && 'opc-inative'" />
                            {{ transformCurrency(getTypeMode('Videochamada', el)?.value) }}
                        </div>
                        <div class="flex align-items-center gap-4"
                            :class="getStatus(getTypeMode('Videochamada', el)?.status)">
                            <img class="clock" src="../../../../assets/icons/clock-time.png" alt=""
                                :class="getStatus(getTypeMode('Videochamada', el)?.status) && 'opc-inative'" />
                            {{ minutesToHm(getTypeMode('Videochamada', el)?.estimatedTime) }} h
                        </div>
                    </div>
                    <!-- Homecare -->
                    <div class="flex flex-column gap-4">
                        <div class="flex align-items-center gap-4"
                            :class="getStatus(getTypeMode('Homecare', el)?.status)">
                            <div class="box-tag">
                                <div class="tag-color" style="background: #FF4500" />
                            </div>
                            <img src="../../../../assets/icons/currency.png" alt=""
                                :class="getStatus(getTypeMode('Homecare', el)?.status) && 'opc-inative'" />
                            {{ transformCurrency(getTypeMode('Homecare', el)?.value) }}
                        </div>
                        <div class="flex align-items-center gap-4"
                            :class="getStatus(getTypeMode('Homecare', el)?.status)">
                            <img class="clock" src="../../../../assets/icons/clock-time.png" alt=""
                                :class="getStatus(getTypeMode('Homecare', el)?.status) && 'opc-inative'" />

                            {{ minutesToHm(getTypeMode('Homecare', el)?.estimatedTime) }} h
                        </div>
                    </div>
                </div>

            </div>
            <div class="flex justify-content-end align-items-center gap-16" style="min-width: 105px;">
                <img class="cursor-pointer" src="../../../../assets/icons/edit-permission.png" alt=""
                    @click="openEditLinkService(el)" />

                <Switch 
                    :addresPlaceid="el.id" 
                    :active="el.status"
                    :disabled="!el.statusserviceoffered"
                    msgDisabled="Serviço inativo em Serviços Oferecidos."
                    @toggle="toggleSwitch" 
                />
            </div>
        </div>
    </div>
    <DialogEditLinkService v-model:visible="viewEditLinkService.status" :obj="viewEditLinkService.obj"
        :addressPlaceId="viewEditLinkService.addressPlaceId" :serviceId="viewEditLinkService.serviceId"
        @save="reloadList" />
    <div class="no-service" v-if="!isLoading && service.length === 0">Nenhum serviço vinculado a este local.</div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import DialogEditLinkService from "./Dialogs/DialogEditLinkService"
// import ServiceType from "./ServiceType.vue";
import Switch from "../../../../components/Switch";
import {
    ListServicesPlaceRequest,
    ChangeStatusToInactiveRequest,
    VerifyChangeStatusToActiveRequest,
} from "../../../core/grpc/generated/professionalProfile_grpc_web_pb";
import profileService from "../../../core/services/professionalProfileService";

export default {
    props: ["item"],
    emits: ["update:item", "save"],
    components: {
        Switch,
        DialogEditLinkService,
    },
    setup(props, { emit }) {
        const viewEditLinkService = ref({
            status: false,
            obj: {},
            addressPlaceid: null,
            serviceId: null,
        });
        const isLoading = ref(false);
        const viewEditService = ref({
            id: "",
            type: "",
            visible: false,
        });
        const listInatives = ref([]);
        const service = ref([]);

        const ListServicesPlace = async addressPlaceId => {
            try {
                isLoading.value = true;
                const req = new ListServicesPlaceRequest()
                req.setAddressplaceid(addressPlaceId)

                const res = await profileService.listServicesPlace(req);
                const { success } = res

                
                if (success) {
                    console.log(res.servicesList)
                    service.value = res.servicesList
                    
                    res.servicesList.map(item => {
                        if (item.status === 'Inativo') {
                            const arr = [...listInatives.value]

                            listInatives.value.push(item.id)
                        }
                    })
                }
                isLoading.value = false
            } catch (error) {
                console.log(error)
            }
        };

        const getTypeMode = (type, item) => {
            const obj = item.servicetypesList.find(item => item.servicemode === type)

            return obj
        }

        const getStatusService = (item) => {
            const obj = item.servicetypesList.find(item => item.servicemode === 'Presencial')

            if (obj) {
                return obj.status
            }

            return null
        }

        const transformCurrency = value => {
            if (value) {
                return value.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                })
            }
            return 'R$ 0,00'
        }

        const minutesToHm = minutes => {
            if (minutes) {
                var sec_num = parseInt(minutes); // don't forget the second param
                var hours = Math.floor(sec_num / 3600);
                var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
                var seconds = sec_num - (hours * 3600) - (minutes * 60);

                if (minutes < 10) { minutes = "0" + minutes; }
                if (seconds < 10) { seconds = "0" + seconds; }
                return minutes + ':' + seconds;

            }
            return "00:00"
        }

        const getStatus = item => {
            if (item === 'Ativo') {
                return null
            }
            return 'inative'
        }

        const editService = (item) => {
            // console.log(item)
            viewEditService.value = {
                id: item.id,
                type: 'Edit',
                visible: true,
            }
        }

        const InactiveService = async (addresPlaceid, serviceId) => {
            try {
                const req = new ChangeStatusToInactiveRequest()
                req.setAddressplaceid(addresPlaceid)
                req.setServiceid(serviceId)

                const res = await profileService.changeStatusToInactive(req);
                const { success } = res

                console.log(res)

                if (res.success) {
                    const data = JSON.parse(res.data)
                    const arr = [...listInatives.value]

                    arr.push(data.id)
                    listInatives.value = arr
                    // emit("save", true)
                }
            } catch (error) {
                console.log(error)
            }
        }

        const ActiveService = async (addresPlaceid, serviceId) => {
            try {
                const req = new VerifyChangeStatusToActiveRequest();
                req.setAddressplaceid(addresPlaceid);
                req.setServiceid(serviceId);

                const res = await profileService.verifyChangeStatusToActive(req);
                const { success } = res

                console.log(res);


                if (res.success) {
                    const data = JSON.parse(res.data)
                    const arr = [...listInatives.value]

                    const arrRemove = arr.filter(item => item !== data.id)

                    listInatives.value = arrRemove
                    // emit("save", true)
                }

            } catch (error) {
                console.log(error)
            }
        }


        const toggleSwitch = (value, addresPlaceid) => {
            if (value === 'Inativo') {
                if (addresPlaceid) {
                    InactiveService(props.item.id, addresPlaceid)
                }
            } else {
                if (value === 'Ativo') {
                    if (addresPlaceid) {
                        const res = ActiveService(props.item.id, addresPlaceid);
                        console.log(service);
                    }
                }
            }
        }

        const openEditLinkService = (item) => {
            const newObj = {
                status: true,
                obj: item,
                addressPlaceId: props.item.id,
                serviceId: item.id,
            }
            // console.log(item)
            viewEditLinkService.value = newObj;
            // console.log('newObj', newObj)
        }

        const reloadList = () => {
            emit("save", true)
        }

        const getInative = id => {
            const res = listInatives.value.find(item => item === id)

            if (res) {
                return true
            } return false
        }

        onMounted(async () => {
            await ListServicesPlace(props.item.id);
        })

        return {
            listInatives,
            service,
            getTypeMode,
            minutesToHm,
            transformCurrency,
            getStatus,
            viewEditService,
            editService,
            isLoading,
            toggleSwitch,
            viewEditLinkService,
            openEditLinkService,
            reloadList,
            getInative,
            ActiveService
        }
    },
};
</script>

<style lang="scss" scoped>
hr {
    color: #F2F2F2;
    margin: 0;
    margin-top: 16px;
}

.opc-inative {
    opacity: .5;
}

.service-type {
    padding: 20px 22px;
    border-radius: 8px;
    box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
}

.name-service {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #4F4F4F;
}

.service-mode {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #828282;
}

.tag-color {
    width: 4px;
    height: 10px;
    background: #A2C0D4;
    border-radius: 2px;
}

.box-tag {
    display: flex;
    justify-content: center;
    width: 8px;

}

.clock {
    margin-left: 12px;
}

.inative {
    color: #E0E0E0;

    .tag-color {
        opacity: .5;
    }
}

.no-service {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 72px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #828282;
    background: #FAFAFA;
    border-radius: 8px;
}

.profission-name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #BDBDBD;
}
.width-names{
    min-width: 200px;
}
.container-presencial{
    width: 138px;
}
.container-videochamada{
    width: 162px;
}

@media (max-width: 1167px) { 
    .gap-164{
        gap: 0 !important;
    }
}
</style>