<template>
  <div>
    <Dialog class="modal" :style="{
      width: '462px',
      borderRadius: '8px',
      zIndex: 1000,
    }" :baseZIndex="0" :autoZIndex="false" :visible="visible" @hide="close" @update:visible="onUpdate">
      <template #header>
        <h5 class="m-auto">Adicionar Local de Atendimento</h5>
      </template>
      <div class="content">
        <div class="container-image">
          <img class="img-avatar" :src="avatar" alt="" v-if="avatar" />
          
          <div v-if="!avatar">
            <label for="avatar" class="flex align-items-center txt-add cursor-pointer gap-4" >
              <img src="../../../../assets/icons/upload-image.png" alt="" />
              Adicionar foto do local
            </label> 
          </div>

          <div class="flex align-itens-center gap-12" v-else>
            <label for="avatar" class="change-avatar txt-white">
              Alterar imagem
              <img src="../../../../assets/icons/myservices/edit-local.png" alt="" />
            </label>
            
            <label class="remove-avatar txt-white gap-8" @click="avatar = ''">
              Remover
              <img src="../../../../assets/icons/myservices/delete-local.png" alt="" />
            </label>
          </div>
        </div>
        <input id="avatar" type="file" @change="changeAvatar" accept="image/png, image/jpeg" />

        <PaletteColor v-model:color="color" />

        <div class="flex flex-column gap-6 mt-16 inputs">
          <InputText 
            type="text" 
            v-model="name" 
            placeholder="Nome do local" 
            :class="(msgErros?.name[0] && !name) && 'p-invalid'"
          />
          <p class="p-invalid" v-if="msgErros?.name[0] && !name">{{msgErros?.name[0]}}</p>

          <InputText 
            type="text" 
            v-model="type" 
            placeholder="Tipo (clínica, hospital, laboratório,... )" 
            :class="(msgErros?.type[0] && !type) && 'p-invalid'"
          />
          <p class="p-invalid" v-if="msgErros?.type[0] && !type">{{msgErros?.type[0]}}</p>
          <Address v-model:fullAddress="address" :msgErros="msgErros" />
        </div>
      </div>

      <template #footer>
        <div class="flex justify-content-end align-items-center h-full">
          <Button label="Salvar" class="addService-button" @click="onSubmit" />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import profileService from "../../../core/services/professionalProfileService";
import {
  AddLocationRequest
} from "../../../core/grpc/generated/professionalProfile_grpc_web_pb";
import axios from 'axios';
import Address from "../../../../components/Address/index.vue";
import PaletteColor from "../../../../components/PaletteColor/PaletteColor.vue";

export default {
  props: ["visible"],
  emits: ["update:visible", "successfull"],
  components: {
    Address,
    PaletteColor,
  },
  setup(props, ctx) {
    const avatar = ref(null);
    const color = ref("#EB5757");
    const name = ref("");
    const type = ref("");
    const address = ref({ cep: "", state: "", city: "", address: "", number: "", complement: "", neighborhood: "" })
    const msgErros = ref({ name: [], type: [], address: [], city: [], neighborhood: [], number: [], postalCode: [], state: [] })
    const close = () => {
      ctx.emit("update:visible", false)
      avatar.value = "";
      name.value = "";
      type.value = "";
      msgErros.value = { name: [], type: [], address: [], city: [], neighborhood: [], number: [], postalCode: [], state: [] }
    }

    const onUpdate = (val) => {
      (!val) && ctx.emit("update:visible", val);
    }

    const onSubmit = () => {
      const obj = {
        avatar: avatar.value ? avatar.value.replace(process.env.VUE_APP_BUCKET_URL + '/', "") : "",
        color: color.value,
        name: name.value,
        type: type.value,
        address: address.value,
      }
      AddLocation(obj)
    }

    const AddLocation = async obj => {
      console.log(obj)
      const request = new AddLocationRequest();
      request.setColor(obj.color);
      request.setName(obj.name);
      request.setType(obj.type);
      request.setUrl(obj.avatar);
      request.setAddress(obj.address.address);
      request.setNumber(obj.address.number);
      request.setComplement(obj.address.complement);
      request.setNeighborhood(obj.address.neighborhood);
      request.setCity(obj.address.city);
      request.setState(obj.address.state);
      request.setPostalcode(obj.address.cep);
      // request.setLatitude(obj.address.address);
      // request.setLongitude(obj.address.address);
      const res = await profileService.addLocation(request);
      console.log(res)

      if (res.success) {
        ctx.emit("successfull", true);
        close();
      } else {
        const data = JSON.parse(res.data)
        console.log(data)
        let newObj = { name: [], type: [], address: [], city: [], neighborhood: [], number: [], postalCode: [], state: [] }

        Object.keys(data).map((item) => {
          newObj = { ...newObj, [item]: data[item] }
        });

        msgErros.value = newObj;
      }
    }

    const changeAvatar = (e) => {
      const token = window.localStorage.getItem("token");
      const file = e.target.files[0];

      const formData = new FormData()
      formData.append('file', file)

      const bearer = {
        'content-type': 'multipart/form-data',
        headers: {
          Authorization: "Bearer " + token,
        }
      };

      axios.post(`${process.env.VUE_APP_FILE_API_URL}/professional/location_image`, formData, bearer)
        .then(function (response) {
          console.log(response)
          const path = process.env.VUE_APP_BUCKET_URL + '/' + response.data.url
          avatar.value = path
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    return {
      close,
      onUpdate,
      onSubmit,
      changeAvatar,
      avatar,
      color,
      name,
      type,
      address,
      msgErros,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-top: 1.5rem;

  .txt-add {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #2F80ED;
    z-index: 10;
  }

  .txt-white{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: white;
  }

  .change-avatar {
    background: #cad1da;
    background: -webkit-linear-gradient(to right, #cad1da, #b8bfb5, #aabcac);
    background: linear-gradient(to right, #cad1da, #b8bfb5, #aabcac);
    z-index: 10;
    border-radius: 100px;
    height: 28px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    gap: 8px;
    cursor: pointer;
  }

  .remove-avatar {
    background: #e3c8c0;
    background: -webkit-linear-gradient(to right, #e3c8c0, #d9c5b9, #cbc7b4);
    background: linear-gradient(to right, #e3c8c0, #d9c5b9, #cbc7b4);
    z-index: 10;
    border-radius: 100px;
    height: 28px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    cursor: pointer;
  }

  p,
  .locais {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #BDBDBD;
  }
}

.container-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  border-radius: 8px;
  border: 1px solid #F2F2F2;
}

.img-avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  z-index: 1;
  object-fit: cover;
}

input[type='file'] {
  display: none;
}

::v-deep(input) {
  border: 1px solid #F2F2F2;
  border-radius: 8px;
  height: 45px;

  &::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #828282;
  }
}

::v-deep(.p-dialog) {
  z-index: 2300 !important;
}
</style>
