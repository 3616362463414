<template>
    <div class="limit p-3">
        <h1 class="title-h1">Locais de Atendimento</h1>

        <div class="container-service" style="margin-bottom: 28px">
            <div class="left">
                <Button class="btn principal" @click="add">Adicionar Local</Button>
                <div class="btns-frequency">
                    <Button class="btn" v-for:="item in btnFilter" :class="isFilter(item.label)"
                        @click="changeFilter(item)">
                        {{ item.label }}
                    </Button>
                </div>
            </div>

            <span class="container-search p-input-icon-left">
                <i class="pi pi-search" v-tooltip.left="'Buscar'" v-if="!isLoading" />
                <i class="pi pi-spin pi-spinner" v-else />
                <InputText class="search__input text-center" type="text" placeholder="Pesquisar" :disabled="isLoading" v-model="searchInput"
                    @change="onChangeSearch($event.target.value)" />
            </span>
        </div>

        <div class="content">
            <NoRegisteredLocation v-if="!isLoading && list.length === 0" />
            <List :list="list" v-if="!isLoading && list.length > 0" @successfull="ListPlaces('Todos')" @reload="reload" />
            <!-- <Loader /> -->
            <Loader v-if="isLoading" />
        </div>
        <DialogAddLocation v-model:visible="visibleAddLocation" @successfull="ListPlaces('Todos')" />
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import NoRegisteredLocation from "./NoRegisteredLocation";
import List from "./List";
import DialogAddLocation from "./DialogAddLocation";
import {
    ListPlacesRequest,
    SearchListPlaceRequest,
} from "../../core/grpc/generated/professionalProfile_grpc_web_pb";
import profileService from "../../core/services/professionalProfileService";
import Loader from "./Loader.vue";

export default {
    //   props: ["",],
    components: {
        NoRegisteredLocation,
        List,
        Loader,
        DialogAddLocation
    },
    setup(props, { emit }) {
        const visibleAddLocation = ref(false);
        const isLoading = ref(false);
        const list = ref([])
        const searchInput = ref("");
        const filter = ref("Todos");
        const btnFilter = [
            { label: "Todos" },
            { label: "Ativos" },
            { label: "Inativos" },
        ];

        const add = () => {
            visibleAddLocation.value = true;
        };

        const isFilter = (item) => {
            if (item !== filter.value) {
                return "inative";
            }
        };

        const changeFilter = (item) => {
            filter.value = item.label;
            if(searchInput.value.length < 3){
                ListPlaces(item.label)
            }else{
                SearchListPlace(searchInput.value);
            }
        };

        const ListPlaces = async (status) => {
            isLoading.value = true
            try {
                const req = new ListPlacesRequest()
                req.setStatus(status)

                const res = await profileService.listPlaces(req);
                const { success } = res

                if (success) {
                    // console.log(res.placesList)
                    list.value = res.placesList
                }

            } catch (error) {
                console.log(error)
            }
            isLoading.value = false
        };

        const SearchListPlace = async word => {
            isLoading.value = true
            if (word.length > 0) {
                try {
                    const req = new SearchListPlaceRequest()
                    req.setStatus(filter.value)
                    req.setWord(word)

                    const res = await profileService.searchListPlace(req);
                    const { success } = res

                    if (success) {
                        console.log(res)
                        list.value = res.placesList
                    }

                } catch (error) {
                    console.log(error)
                }
            }else{
                ListPlaces(filter.value)
            }
            isLoading.value = false
        }

        const onChangeSearch = word => {
            SearchListPlace(word)
        }

        const reload = () => {
            ListPlaces(filter.value)
        }

        onMounted(() => {
            ListPlaces("Todos");
        });

        return {
            visibleAddLocation,
            add,
            filter,
            btnFilter,
            isFilter,
            changeFilter,
            isLoading,
            searchInput,
            list,
            ListPlaces,
            onChangeSearch,
            reload,
        }
    },
};
</script>


<style lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 22px;
}

.limit {
    display: flex;
    flex-direction: column;
    max-width: 100%;

    .row-between {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.container-service {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 36px;

    .principal {
        display: flex;
        justify-content: center;
        width: 176px;
        background: linear-gradient(171.55deg, #FF6A33 60.76%, #FF4500 99.68%);
    }

    input {
        width: 200px;
    }

    .search__input {
        box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
    }

    .left {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 278px);
        gap: 10px;

        @media (max-width: 970px) {
            width: 100%;
            margin-bottom: 10px;
        }

        @media (max-width: 500px) {
            flex-direction: column;
            margin-bottom: 10px;
        }
    }
}

.btn {
    width: max-content;
    height: 40px;
}

.btns-frequency {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;

    .inative {
        background: #fff !important;
        border: 1px solid #fff;
        color: #828282;
    }

    .btn:nth-child(1) {
        background: #FF6A33;
        padding: 0px 32px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .btn:nth-child(2) {
        background: #FF6A33;
        padding: 0px 32px;
        border-radius: 0px;
    }

    .btn:nth-child(3) {
        background: #FF6A33;
        padding: 0px 32px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    @media (max-width: 995px) {
        justify-content: flex-start;
    }

    @media (max-width: 456px) {
        justify-content: flex-start;
        margin-bottom: 10px;
    }
}

::v-deep(input) {
    height: 40px;
    border-radius: 8px;
    padding: 0 19px;
    color: #bdbdbd;
    border: 1px solid white;
    outline: none;

    &::-webkit-input-placeholder {
        color: #bdbdbd;
    }

    &:focus {
        border-color: #ff6a33;
    }
}

::v-deep(.container-search) {
    width: 260px;

    input {
        width: 100% !important;
    }
}

::v-deep(.pi, .pi-search) {
    color: #BDBDBD !important;
}

::v-deep(.p-input-icon-right) {
    cursor: pointer;
    vertical-align: middle;
}
</style>