<template>
    <div class="loader">
        <div class="flex align-items-center flex-wrap justify-content-between gap-8">
            <div class="flex">
                <Skeleton size="96px" />

                <div class="flex flex-column gap-12 ml-16">
                    <div class="flex align-items-center gap-16">
                        <Skeleton width="108px" height="16px" />
                        <Skeleton size="16px" />
                        <Skeleton width="14px" height="16px" />
                    </div>
                    <div class="flex flex-column description-local gap-8">
                        <Skeleton width="247px" height="16px" />
                        <Skeleton width="200px" height="16px" />
                        <Skeleton width="240px" height="16px" />
                    </div>
                </div>
            </div>
            <div class="flex align-items-center gap-16">
                <Skeleton width="60px" height="18px" />
                <Skeleton width="42px" height="24px" borderRadius="16px" />
            </div>
            
        </div>
    </div>
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    //   props: ["",],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.loader {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(61, 71, 102, 0.08);
    backdrop-filter: blur(8px);
    border-radius: 8px;
    padding: 24px;
    .hr{
        margin-top: 40px;
        margin-bottom: 16px;
    }
}


</style>