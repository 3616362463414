<template>
    <div class="loader-linked-service">
        <Skeleton width="100%" height="45px" borderRadius="8px" />

        <Skeleton class="mt-24" width="160px" height="16px" borderRadius="4px" />
        <Skeleton class="mt-8" width="100%" height="1px" />

        <Skeleton class="mt-24" width="200px" height="16px" borderRadius="4px" />
        <Skeleton class="mt-24" width="140px" height="16px" borderRadius="4px" />
        <Skeleton class="mt-24" width="80px" height="16px" borderRadius="4px" />
        <Skeleton class="mt-24" width="120px" height="16px" borderRadius="4px" />
    </div>
</template>

<script>

export default {
    setup(props, { emit }) {

        return {
        }
    },
};
</script>


<style lang="scss" scoped>
.loader-linked-service {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1.5rem;
}


</style>