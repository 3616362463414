<template>
    <div class="profile flex align-items-center flex-wrap justify-content-between">
        <div class="flex align-items-center">
            <img class="img-profile" :src="getUrl(item.pictureUrl)" v-if="item.pictureUrl" />
            <img class="img-profile" src="../../../../assets/images/no-avatar.png" v-else />

            <div class="flex justify-content-center flex-column gap-6 ml-16">
                <div class="flex align-items-center gap-8">
                    <div class="tag-color" :style="{background: item.color}" />
                    <p class="name-local">{{ item.name }}</p>
                </div>
                <div class="flex align-items-center description-local">
                    <p>{{ item.address }}, Nº {{ item.number }} - {{ item.city }}, {{ item.state }}, {{ item.country }}
                    </p>
                </div>
            </div>
        </div>
        <div class="flex align-items-center gap-16">
            <div class="flex align-items-center gap-6 cursor-pointer"
                v-tooltip.top="'Editar ' + '<i>' + item.name + '</i>'" @click="changeEdit(item)">
                <span>Editar dados</span>
                <img src="../../../../assets/icons/edit-permission.png" alt="" />
            </div>
            <div class="flex align-items-center gap-6 cursor-pointer"
                v-tooltip.top="'Remover ' + '<i>' + item.name + '</i>'" @click="visibleDelete = true">
                <span>Apagar</span>
                <img src="../../../../assets/icons/delete-location.png" alt="" />
            </div>
            <img class="cursor-pointer" src="../../../../assets/icons/arrow-menu.png" alt=""
                @click="$emit('toggle', true)" v-if="visible" />
            <img class="cursor-pointer" src="../../../../assets/icons/down-menu.png" alt=""
                @click="$emit('toggle', true)" v-else />

            <InputSwitch class="switch" :trueValue="item.status === 'Ativo' ? false : true" @click="onChangeStatus()" />
        </div>
    </div>
    <DialogEditLocation 
        v-model:visible="dataEdit.status" 
        :obj="dataEdit.item" 
        v-if="dataEdit.status" 
        @update:success="$emit('onSuccess', true)"
    />
    <!-- <DialogEditFutureCommitments v-model:visible="visibleEdit" /> -->
    <DialogDeleteLocation v-model:visible="visibleDelete" :id="item.id" @successfull="deleteSuccessfull" @failedDeleteLocation="errorDeleteLocation" />    

    <DialogExcludedLocation v-model:visible="visibleExcludedLocation" @successfull="$emit('onSuccess', true)" />
    <!-- <DialogFutureCommitments v-model:visible="visibleDelete" /> -->
    <!-- <DialogTimesAvailable v-model:visible="visibleDelete" /> -->
    <DialogUnableDeleteLocation v-model:visible="visibleUnableDeleteLocation.status" :msg="visibleUnableDeleteLocation.msg" />
</template>

<script>
import { watch, onMounted, ref } from "vue";
import DialogEditLocation from "./Dialogs/DialogEditLocation";
import DialogEditFutureCommitments from "./Dialogs/DialogEditFutureCommitments";
import DialogDeleteLocation from "./Dialogs/DialogDeleteLocation";
import DialogExcludedLocation from "./Dialogs/DialogExcludedLocation";
import DialogFutureCommitments from "./Dialogs/DialogFutureCommitments";
import DialogTimesAvailable from "./Dialogs/DialogTimesAvailable";
import DialogUnableDeleteLocation from "./Dialogs/DialogUnableDeleteLocation";

import {
    UpdateStatusPlaceRequest,
} from "../../../core/grpc/generated/professionalProfile_grpc_web_pb";
import profileService from "../../../core/services/professionalProfileService";

export default {
    props: ["item", "visible"],
    emits: ["onSuccess", "toggle", "save"],
    components: {
        DialogEditLocation,
        DialogDeleteLocation,
        DialogExcludedLocation,
        DialogFutureCommitments,
        DialogTimesAvailable,
        DialogEditFutureCommitments,
        DialogUnableDeleteLocation
    },
    setup(props, { emit }) {
        const status = ref(props.item.status);
        const dataEdit = ref({
            status: false,
            item: ""
        });
        const visibleDelete = ref(false);
        const visibleExcludedLocation = ref(false);
        const visibleUnableDeleteLocation = ref({status: false, msg: ""});

        const getUrl = path => {
            return process.env.VUE_APP_BUCKET_URL + '/' + path;
        };

        const deleteSuccessfull = path => {
            // emit("successfull", true);
            visibleExcludedLocation.value = true
        };

        const UpdateStatusPlace = async ({ Addressplaceid, Status }) => {
            try {
                const req = new UpdateStatusPlaceRequest();
                req.setAddressplaceid(Addressplaceid)
                req.setStatus(Status)
                const res = await profileService.updateStatusPlace(req);

                return res
            } catch (error) {

            }
        }

        const onChangeStatus = async () => {
            const obj = {
                Addressplaceid: props.item.id,
                Status: props.item.status
            }
            if(props.item.status == "Ativo"){
                obj.Status = "Inativo"
            }else{
                if(props.item.status == "Inativo"){
                    obj.Status = "Ativo"
                }
            }

            const res = await UpdateStatusPlace(obj)
            if (res.success) {
                console.log("mudou!")
                console.log(res)
            }
            emit('save');
        }

        const errorDeleteLocation = (msg) => {
            if(msg === 'Não é possível excluir o local, pois este possuí serviços vinculados!'){
                visibleUnableDeleteLocation.value = {status: true, msg: msg}
            }
        }

        const changeEdit = (item) => {
            dataEdit.value = {
                status: true,
                item: item
            }
        }

        watch(() => status.value,
            async (newValue, oldValue) => {
                const obj = {
                    Addressplaceid: props.item.id,
                    Status: newValue
                }
                const res = await UpdateStatusPlace(obj)
                if (res.success) {
                    console.log("mudou!")
                    console.log(res)
                }
                emit('save');
            })

        return {
            status,
            dataEdit,
            visibleDelete,
            visibleUnableDeleteLocation,
            getUrl,
            deleteSuccessfull,
            visibleExcludedLocation,
            onChangeStatus,
            errorDeleteLocation,
            changeEdit,
        }
    },
};
</script>


<style lang="scss" scoped>
.profile {
    background: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 8.72508px 26.1752px rgba(61, 71, 102, 0.08), 0px 3.2719px 6.54381px rgba(61, 71, 102, 0.06);
}

.img-profile {
    width: 99px;
    height: 99px;
    border-radius: 10px;
}

.name-local {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
}

.description-local {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #828282;
}

.switch {
    margin-right: 14px;
}

.status {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.tag-color{
    width: 14px;
    height: 14px;
    border-radius: 14px;
}

span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #828282;
}
</style>