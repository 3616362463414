<template>
    <div class="content">
        <span class="p-input-icon-left">
            <AutoComplete 
                v-model="textValue" 
                :suggestions="filteredSearch" 
                @complete="searchService($event)" 
                optionLabel="name"
                field="name"
                placeholder="Buscar serviço"
                :forceSelection="true"
                @item-select="selectService"
            />
            <i class="pi pi-search" />
        </span>
    </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
// import A from "./A";
import profileService from "../../../../../core/services/professionalProfileService";
import { SearchActiveServiceRequest } from "../../../../../core/grpc/generated/professionalProfile_grpc_web_pb";

export default {
    props: ["search", "addressPlaceId"],
    emits: ["update:search"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        const textValue = ref("");
        const filteredSearch = ref([]);

        const SearchActiveService = async (word) => {
            try {
                const req = new SearchActiveServiceRequest();
                req.setWord(word);
                req.setAddressplaceid(props.addressPlaceId);
                const res = await profileService.searchActiveService(req);
                return res
            } catch (error) {
                console.log(error)
            }
        }

        const searchService = async (item) => {
            const res = await SearchActiveService(item.query)
            console.log(res);
            filteredSearch.value = res.serviceactivenamesList;
        };

        const selectService = (e) => {
            console.log(e.value)
            emit("update:search", e.value);
        }

        return {
            textValue,
            filteredSearch,
            searchService,
            selectService,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }
.p-autocomplete{
    width: 100%;
    height: 45px;
}

::v-deep(.p-autocomplete-input) {
    padding-left: 30px;
    
    border-top: 1px solid #F2F2F2;
}
</style>