<template>
    <div class="flex flex-column gap-8 relative mt-8">
        <div class="container-color">
            <p class="legend">Escolha a cor do local na agenda</p>
            <div class="color gap-8 p-8" v-if="loaded">
                <div class="box-color pick" :style="getColor()" @click="changeDrop">
                    {{  getColor() === 'background: #E0E0E0' ? 'x' : ''  }}
                </div>
                <div class="box-drop" @click="changeDrop">
                    <img src="../../assets/icons/colorpick/down.png" alt="" v-if="!viewPallete" />
                    <img src="../../assets/icons//colorpick/up.png" alt="" v-else />
                </div>
            </div>
        </div>

        <div class="palette" :class="getAnimation()" v-if="viewPallete">
            <div class="box-color" :class="!item.enable && 'color-disable'" :style="getColor(item.color)"
                v-for="(item, index) in colors" @click="item.enable && changeColor(item.color)">
                <img src="../../assets/icons/colorpick/color-disabled.png" alt="" v-if="!item.enable" />
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { EmptyRequest } from "../../modules/core/grpc/generated/professionalProfile_grpc_web_pb";
import profileService from "../../modules/core/services/professionalProfileService";

export default {
    props: ["color", "edition"],
    emits: ["update:color"],
    setup(props, { emit }) {
        const defaultColor = ref(null)
        const loaded = ref(false);
        const hexColor = ref('#EB5757');
        const viewPallete = ref(false);
        const animation = ref(true);
        const colors = ref([
            { color: "#FF4B4B", enable: true },
            { color: "#FF9254", enable: true },
            { color: "#EAEE3A", enable: true },
            { color: "#75A823", enable: true },
            { color: "#AFEC4C", enable: true },
            { color: "#61FFC6", enable: true },
            { color: "#72EEFF", enable: true },
            { color: "#00D1FF", enable: true },
            { color: "#0094FF", enable: true },
            { color: "#0500FF", enable: true },
            { color: "#7000FF", enable: true },
            { color: "#AD00FF", enable: true },
            { color: "#DB00FF", enable: true },
            { color: "#FF95F4", enable: true },
            { color: "#B6008E", enable: true },
            { color: "#C5002F", enable: true },
            { color: "#D06400", enable: true },
            { color: "#7E0000", enable: true },
            { color: "#000000", enable: true },
            { color: "#616161", enable: true },
            { color: "#7D5E0D", enable: true },
            { color: "#817932", enable: true },
            { color: "#FFB800", enable: true },
            { color: "#3F556F", enable: true },
        ]);

        const getColor = (color) => {
            if (color) {
                if (color === "#FFFFFF") {
                    return 'background: #FFFFFF; border: 1px solid #BDBDBD;'
                }
                return String('background: ' + color);
            }
            return String('background: ' + hexColor.value);

        }

        const changeDrop = () => {
            animation.value = !animation.value;

            if (animation.value) {
                setTimeout(() => {
                    viewPallete.value = !viewPallete.value;
                }, 501);
            } else {
                viewPallete.value = !viewPallete.value;
            }
        }

        const changeColor = color => {
            hexColor.value = color;
            emit("update:color", color);
            changeDrop();
        }

        const getAnimation = () => {
            if (animation.value) {
                return 'animation-fadeout';
            }

            return 'animation-fadein'
        }

        const getColorsAlreadyUsed = async () => {
            try {
                const req = new EmptyRequest()

                const res = await profileService.listColors(req);
                const { success } = res

                if (res.success) {
                    const data = JSON.parse(res.data)
                    const { color } = data
                    const arr = [...colors.value];

                    // console.log(color);

                    arr.map(item => {
                        if(color.find(itemColor => itemColor === item.color)) {
                            item.enable = false;
                        }
                    })

                    colors.value = arr;
                }
            } catch (error) {
                console.log(error)
            }

        }

        const setColorEnable = () => {
            const item = colors.value.find(item => item.enable)

            // console.log('setou', item);

            hexColor.value = item.color;
            if(item){
                emit("update:color", item.color)
            }
        }

        const EnableMyDefaultColor = color => {
            const arr = [...colors.value]
            // console.log(color);
            // console.log(color);
            
            arr.map(item => {
                // console.log(item);
                if(item.color === color) {
                    // console.log('habilitar', color);
                    item.enable = true;
                }
            })
            // console.log(arr);
            colors.value = arr;
        }

        watch(
            () => props.color,
            (newValue, oldValue) => {
                if (newValue !== hexColor.value) {
                    hexColor.value = newValue;
                }
            }
        );

        onMounted(async () => {
            defaultColor.value = props.color;

            await getColorsAlreadyUsed();
            if(!props.edition){
                await setColorEnable()
            }else{
                hexColor.value = defaultColor.value;
                await EnableMyDefaultColor(defaultColor.value);
            }
            loaded.value = await true;
        })

        return {
            hexColor,
            getColor,
            colors,
            changeColor,
            viewPallete,
            getAnimation,
            changeDrop,
            loaded,
        }
    },
};
</script>


<style lang="scss" scoped>
.color {
    display: flex;
    align-items: center;
    width: max-content;

    .box-drop {
        padding: 5px 2px;
        cursor: pointer;
    }
}

.container-color {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FAFAFA;
    border-radius: 8px;
    padding: 0 16px;
}

.box-color {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 30px;
}

.pick {
    width: 76px;
    height: 20px;
    cursor: pointer;
}

.legend {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #828282;
}

.color-disable {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #E0E0E0;
    cursor: not-allowed !important;
}

.palette {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100%;
    width: max-content;
    padding: 16px;
    gap: 6px;
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    background: white;

    .box-color {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #BDBDBD;
        border-radius: 100%;
    }
}

.animation-fadein {
    -webkit-animation: fadein 0.5s ease-in alternate;
    -moz-animation: fadein 0.5s ease-in alternate;
    animation: fadein 0.5s ease-in alternate;
}

.animation-fadeout {
    -webkit-animation: fadeout 0.5s ease-in alternate;
    -moz-animation: fadeout 0.5s ease-in alternate;
    animation: fadeout 0.5s ease-in alternate;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}


</style>