<template>
    <Dialog class="modal" :style="{
        width: '402px',
        borderRadius: '8px',
    }" :visible="visible" :closable="false" @hide="close" @update:visible="onUpdate">
        <template #header>
            <h5 class="m-auto">Compromissos Futuros</h5>
        </template>

        <div class="content">
            <div class="flex flex-column" v-if="!nextPage">
                <p>Horários futuros para esse local não sofreram alterações com a exclusão deste.</p>

                <p class="mt-16">A partir de agora novos agendamentos para esse local <b>não estarão mais
                        disponíveis.</b></p>
            </div>
            <div v-else>
                <p>Este local foi excluído com sucesso.</p>
            </div>

            <div class="buttons">
                <Button class="btn-cancel" label="Cancelar" @click="nextPage = true" />
                <Button label="Excluir" @click="close" />
            </div>
        </div>
    </Dialog>
</template>

<script>
import { ref, onMounted } from 'vue';
export default {
    props: ["visible"],
    emits: ["update:visible", "closeall"],
    setup(_, { emit }) {
        const nextPage = ref(false);
        const close = () => {
            emit("update:visible", false)
            clear()
        }
        const onUpdate = val => (!val) && emit("update:visible", val);

        const CloseAll = () => {
            close();
            emit("update:visible", false);
            // emit("closeall", true);
        }

        const clear = () => {
            nextPage.value = false;
        }

        return {
            close,
            onUpdate,
            CloseAll,
            nextPage
        };
    },
};
</script>

<style lang="scss" scoped>
.content {
    margin-top: 1.5rem;
    margin-bottom: -10px;

    p {
        max-width: 300px;
        text-align: center;
        margin: 0 auto;
        color: #828282;
    }

    .buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 8px;
        margin-top: 24px;

        .btn-cancel {
            background: white;
            border: 1px solid white;
            box-shadow: none;
            color: #FF4500;
        }
    }
}

.mt-16 {
    margin-top: 16px !important;
}

::v-deep(button) {
    max-width: 104px;
    height: 40px;
}
</style>
