<template>
    <div class="loader-linked-service">
        <Skeleton width="100%" height="169px" borderRadius="8px" />

        <div class="type">
            <div class="title">
                <Skeleton width="16px" height="16px" borderRadius="4px" />
                <Skeleton width="80px" height="16px" borderRadius="4px" />
            </div>
            <Skeleton width="40px" height="24px" borderRadius="16px" />
        </div>
        
        <div class="type">
            <div class="title">
                <Skeleton width="16px" height="16px" borderRadius="4px" />
                <Skeleton width="100px" height="16px" borderRadius="4px" />
            </div>
            <Skeleton width="40px" height="24px" borderRadius="16px" />
        </div>
        
        <div class="type">
            <div class="title">
                <Skeleton width="16px" height="16px" borderRadius="4px" />
                <Skeleton width="60px" height="16px" borderRadius="4px" />
            </div>
            <Skeleton width="40px" height="24px" borderRadius="16px" />
        </div> 
    </div>
</template>

<script>

export default {
    setup(props, { emit }) {

        return {
        }
    },
};
</script>


<style lang="scss" scoped>
.loader-linked-service {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1.5rem;

    
}
.type{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #E9EAEC;
    border-radius: 8px;
    margin-top: 12px;
    padding: 8px 16px;
    height: 50px;
    .title{
        display: flex;
        align-items: center !important;
        height: max-content;
        gap: 10px;
    }
}

</style>