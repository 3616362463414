<template>
    <div class="card-list" v-for="(item, index) in list" :key="item.id" :style="item.status === 'Inativo' && 'opacity: .6;'">
        <Profile 
            :item="item" 
            :visible="expanded[index]" 
            @onSuccess="deleteSuccessfull"  
            @toggle="toggle(index)"
            @save="reload"
        />
        <ServicesOffered :item="item" :visible="expanded[index]" @save="reload" />
    </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import Profile from "./Profile.vue";
import ServicesOffered from "./ServicesOffered.vue";

export default {
    props: ["list"],
    emits: ["successfull", "reload"],
    components: {
        Profile,
        ServicesOffered,
    },
    setup(props, { emit }) {
        const expanded = ref([]);

        const deleteSuccessfull = () => {
            emit("successfull", true);
        }

        const toggle = (index) => {
            const arr = [...expanded.value];
            arr[index] = !arr[index];
            expanded.value = arr;
        }

        const reload = () => {
            emit("reload");
        }

        onMounted(() => {
            const arr = Array(props.list.length).fill(false);
            expanded.value = arr;
            // console.log(arr)
            console.log(props.list)
        })    

        return {
            deleteSuccessfull,
            expanded,
            toggle,
            reload
        }
    },
};
</script>


<style lang="scss" scoped>
.card-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(61, 71, 102, 0.08);
    backdrop-filter: blur(8px);
    border-radius: 8px;
}
</style>