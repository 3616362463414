<template>
    <div class="container-bg" :class="!visible && 'hidden'">
        <div class="service-offered flex align-items-center flex-wrap justify-content-between">
            <p class="service-title">Serviços oferecidos</p>
            <div class="flex align-items-center service-types">
                <div class="flex align-items-center gap-4">
                    <div class="tag-color" />
                    Presencial
                </div>
                <div class="hr" />
                <div class="flex align-items-center gap-4">
                    <div class="tag-color" style="background: #333333" />
                    Videochamada
                </div>
                <div class="hr" />
                <div class="flex align-items-center gap-4">
                    <div class="tag-color" style="background: #FF4500" />
                    Homecare
                </div>
            </div>
            <button class="link-service" @click="openLinkService">
                Vincular serviço
                <img src="../../../../assets/icons/location/link-service-btn.png" alt="" />
            </button>
        </div>
        <ServiceType :item="item" @save="reload" />
    </div>

    <DialogLinkService v-model:visible="viewLinkService" :addressPlaceId="item.id" @save="reload" />

    <!-- <DialogRegisterProfessionalInative v-model:visible="viewNoServiceRegistered" /> -->
    <DialogNoServiceRegistered v-model:visible="viewNoServiceRegistered" />
    <DialogNoActiveService v-model:visible="viewNoActiveService" />
</template>

<script>
import { ref, onMounted } from "vue";
import ServiceType from "./ServiceType.vue";
import DialogLinkService from "./Dialogs/DialogLinkService/index.vue";
import DialogRegisterProfessionalInative from "./Dialogs/DialogRegisterProfessionalInative.vue";
import DialogNoServiceRegistered from "./Dialogs/DialogNoServiceRegistered.vue";
import DialogNoActiveService from "./Dialogs/DialogNoActiveService.vue";

import {
    VerifyUfActiveRequest,
} from "../../../core/grpc/generated/professionalProfile_grpc_web_pb";
import profileService from "../../../core/services/professionalProfileService";

export default {
    props: ["item", "visible"],
    emits: ["save"],
    components: {
        ServiceType,
        DialogLinkService,
        DialogRegisterProfessionalInative,
        DialogNoServiceRegistered,
        DialogNoActiveService,
    },
    setup(props, { emit }) {
        const viewLinkService = ref(false);
        const viewNoServiceRegistered = ref(false);
        const viewNoActiveService = ref(false);

        const VerifyUfActive = async () => {
            try {
                const req = new VerifyUfActiveRequest();
                // req.setState(props.item.state);
                req.setAddressplaceid(props.item.id);
                const res = await profileService.verifyUfActive(req);

                return res
            }
            catch (error) {
                console.log(error)
            }
        }

        const openLinkService = async () => {
            const res = await VerifyUfActive();

            // console.log(res)

            if (res.success) {
                viewLinkService.value = true;
            } else {
                if (res.errormessage === 'Para vincular um serviço a este local você precisa primeiro cadastrá-lo em Serviços Oferecidos.') {
                    viewNoServiceRegistered.value = true;
                } else if (res.errormessage === 'Todos os serviços ativos já foram vinculados a este local.') {
                    viewNoActiveService.value = true;
                }
            }
        }

        const reload = () => {
            emit("save");
        }

        // onMounted(() => {
        //     console.log(props.item)
        // })

        return {
            viewLinkService,
            viewNoServiceRegistered,
            viewNoActiveService,
            openLinkService,
            reload
        }
    },
};
</script>

<style lang="scss" scoped>
hr {
    padding: 0;
    margin: 0;
    margin-top: 24px;
    border-color: #F2F2F2;
}

.service-offered {
    margin-top: 19px;
}

.container-bg {
    display: flex;
    flex-direction: column;
    padding: 14px;
    gap: 24px;
    width: 100%;
}

.service-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #BDBDBD;
}

.tag-color {
    width: 4px;
    height: 10px;
    background: #A2C0D4;
    border-radius: 2px;
}

.service-types {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #828282;
}

.link-service {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border-radius: 100px;
    border: 1px solid #FF4500;
    background: white;
    white-space: nowrap;
    min-width: 149px;
    height: 30px;
    color: #FF4500;
    cursor: pointer;
}

.active {
    filter: invert(51%) sepia(48%) saturate(2559%) hue-rotate(338deg) brightness(101%) contrast(103%);
}

.hr{
    width: 2px;
    height: 28px;
    background: #F2F2F2;
    margin: 0 36px;
}
</style>