<template>
    <div class="no-service">
        <img src="../../../assets/icons/myservices/home.png" alt="" />
        Nenhum local de atendimento cadastrado.
    </div>
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    //   props: ["",],
    // components: {
    //     List,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>

<style lang="scss" scoped>
.no-service {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;
    background: white;
    padding: 62px 0;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    color: #BDBDBD;
}


</style>