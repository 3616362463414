<template>
    <div 
        class="switch" 
        :class="[String(bool), cursorNotAllowed]" 
        v-tooltip.top="{value: msgDisabled, disabled: !disabled}"
        @click="toggle"
    >
        <div class="circle" />
    </div>
</template>

<script>
import { onMounted, ref,computed } from "vue";
// import A from "./A";

export default {
    props: ["addresPlaceid", "active", "disabled", "msgDisabled"],
    emits: ["toggle"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        const bool = ref(true);
        const type = ref("");
        const cursorNotAllowed = computed(() => {
            if(props.disabled){
                return 'cursor-not-allowed'
            }
            return ''
        })

        const getBool = () => {
            if (bool.value) {
                if (type.value === 'string') {
                    return 'Ativo'
                } else {
                    if (type.value === 'boolean') {
                        return true
                    }
                }
            } else {
                if (type.value === 'string') {
                    return 'Inativo'
                } else {
                    if (type.value === 'boolean') {
                        return false
                    }
                }
            }
        }

        const toggle = async (e) => {
            if(!props.disabled){
                bool.value = !bool.value;
                const boolActive = await getBool();
                emit("toggle", boolActive, props.addresPlaceid);
            }
        };

        onMounted(() => {
            if (typeof props.active === 'string') {
                type.value = 'string'
            }
            if (typeof props.active === 'boolean') {
                type.value = 'boolean'
            }

            if (props.active === 'Inativo') {
                bool.value = false;
            }
            emit("toggle", props.active);
        })

        return {
            bool,
            toggle,
            cursorNotAllowed
        }
    },
};
</script>


<style lang="scss" scoped>
.switch {
    display: flex;
    align-items: center;
    width: 24px;
    height: 16px;
    border-radius: 8px;
    padding: 2px;
    cursor: pointer;

    .circle {
        width: 8px;
        height: 8px;
        background: red;
        border-radius: 6px;
    }
}

.true {
    border: 2px solid #FF6A33 !important;
    justify-content: flex-end;

    .circle {
        background: #FF6A33;
    }
}

.false {
    border: 2px solid #BDBDBD !important;
    justify-content: flex-start;

    .circle {
        background: #BDBDBD;
    }
}


</style>