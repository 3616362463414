<template>
    <Loader v-if="isLoading" />
    <div class="item-selected" v-else>
        <div class="header">
            <div class="flex align-items-center gap-8">
                <h1>{{ item.name }}</h1>
                <p class="cursor-pointer" @click="$emit('clear', true)">X</p>
            </div>
            <p>{{ profission }}:</p>
            <!-- {{ list_specialities }} -->
            <ul v-for="item in list_specialities" :key="item.description" v-if="list_specialities.length > 0">
                <li class="font-medium">{{ item.description }}</li>
                <ul v-for="actuation in item.actuationList" v-if="item.actuationList.length > 0">
                    <li>{{ actuation.description }}</li>
                </ul>
            </ul>
            <b>Descrição:</b>
            <p>{{ description }}</p>
            <div class="flex align-items-center">
                <b>Direito a retorno após a consulta: </b>
                <p class="ml-8"> {{ pb_return }} dias</p>
            </div>
        </div>

        <Desconto tipo="Presencial" v-model:active="presencialModel.active" v-model:duration="presencialModel.duration"
            v-model:value="presencialModel.valueInput" v-model:porcent="presencialModel.porcent" v-if="!isLoading" />

        <Desconto tipo="Videochamada" v-model:active="videochamadaModel.active"
            v-model:duration="videochamadaModel.duration" v-model:value="videochamadaModel.valueInput"
            v-model:porcent="videochamadaModel.porcent" v-if="!isLoading" />
        <Desconto tipo="Homecare" v-model:active="homecareModel.active" v-model:duration="homecareModel.duration"
            v-model:value="homecareModel.valueInput" v-model:porcent="homecareModel.porcent"
            v-model:km="homecareModel.km" v-model:raio="homecareModel.raio" v-if="!isLoading" />
    </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import Desconto from "../../../../Desconto"
import profileService from "../../../../../../core/services/professionalProfileService";
import {
    ListServiceIdLocationRequest
} from "../../../../../../core/grpc/generated/professionalProfile_grpc_web_pb";
import Loader from "./Loader";

export default {
    props: ["item", "inPerson", "videoCall", "homeCare"],
    emits: ["clear", "update:inPerson", "update:videoCall", "update:homeCare"],
    components: {
        Desconto,
        Loader,
    },
    setup(props, { emit }) {
        const isLoading = ref(true);
        const profission = ref("");
        const description = ref("");
        const pb_return = ref(0);
        const list_specialities = ref([]);
        let presencialModel = ref({
            active: false,
            duration: "00:00",
            valueInput: 0,
            porcent: 0
        });
        const videochamadaModel = ref({
            active: false,
            duration: "00:00",
            valueInput: 0,
            porcent: 0
        });

        const homecareModel = ref({
            active: false,
            duration: "00:00",
            valueInput: 0,
            porcent: 0,
            km: 0,
            raio: 0
        });

        const getServiceMode = (type, list) => {
            const res = list.find(service => service.servicemode === type)
            return res
        }

        const minutesToHm = minutes => {
            if (minutes) {
                var sec_num = parseInt(minutes); // don't forget the second param
                var hours = Math.floor(sec_num / 3600);
                var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
                var seconds = sec_num - (hours * 3600) - (minutes * 60);

                if (minutes < 10) { minutes = "0" + minutes; }
                if (seconds < 10) { seconds = "0" + seconds; }
                return minutes + ':' + seconds;

            }
            return "00:00"
        }

        const getInfoService = async (id) => {
            // console.log(id)
            try {
                const req = new ListServiceIdLocationRequest();
                req.setServiceid(id);

                const res = await profileService.listServiceIdLocation(req);

                if (res.success) {
                    console.log(res.servicesList[0]);
                    const presencial = getServiceMode('Presencial', res.servicesList[0].servicetypesList)
                    const videochamada = getServiceMode('Videochamada', res.servicesList[0].servicetypesList)
                    const homecare = getServiceMode('Homecare', res.servicesList[0].servicetypesList)
                    
                    description.value = res.servicesList[0].description
                    pb_return.value = res.servicesList[0].pb_return
                    list_specialities.value = res.servicesList[0].councilList[0].specialityList
                    profission.value = res.servicesList[0].councilList[0].council + " - " + res.servicesList[0].councilList[0].state

                    if (presencial) {
                        presencialModel.value = {
                            active: presencial.status === 'Ativo' ? true : presencial.status === 'Inativo' && false,
                            duration: minutesToHm(presencial.estimatedTime),
                            valueInput: presencial.value,
                            porcent: 0,
                        }
                    }
                    if (videochamada) {
                        videochamadaModel.value = {
                            active: videochamada.status === 'Ativo' ? true : videochamada.status === 'Inativo' && false,
                            duration: minutesToHm(videochamada.estimatedTime),
                            valueInput: videochamada.value,
                            porcent: 0,
                        }
                    }
                    if (homecare) {
                        // console.log(homecare)
                        homecareModel.value = {
                            active: homecare.status === 'Ativo' ? true : homecare.status === 'Inativo' && false,
                            duration: minutesToHm(homecare.estimatedTime),
                            valueInput: homecare.value,
                            porcent: 0,
                            km: homecare.valuekm,
                            raio: homecare.maxDisplacement,
                        }
                    }
                }

            } catch (error) {
                console.log(error)
            }
        }

        watch(
            () => [
                presencialModel.value.active,
                presencialModel.value.duration,
                presencialModel.value.valueInput,
                presencialModel.value.porcent,
            ],
            (newValue, oldValue) => {
                emit("update:inPerson", presencialModel.value)
            }
        );

        watch(
            () => [
                videochamadaModel.value.active,
                videochamadaModel.value.duration,
                videochamadaModel.value.valueInput,
                videochamadaModel.value.porcent,
            ],
            (newValue, oldValue) => {
                emit("update:videoCall", videochamadaModel.value)
            }
        );

        watch(
            () => [
                homecareModel.value.active,
                homecareModel.value.duration,
                homecareModel.value.valueInput,
                homecareModel.value.porcent,
                homecareModel.value.km,
                homecareModel.value.raio,
            ],
            (newValue, oldValue) => {
                emit("update:homeCare", homecareModel.value)
            }
        );

        onMounted(async () => {
            await getInfoService(props.item.id)
            isLoading.value = false;
        })

        return {
            isLoading,
            presencialModel,
            videochamadaModel,
            homecareModel,
            profission,
            description,
            pb_return,
            list_specialities,
        }
    },
};
</script>


<style lang="scss" scoped>
.item-selected {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 1.5rem;

    .header {
        padding: 12px 16px;
        background: #FAFAFA;
        border-radius: 8px;
        margin-bottom: 8px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #828282;

        h1 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: #828282;
        }
    }
}

ul {
    margin: 0;
    padding: 5px 18px;

    ul {
        margin: 0;
        padding: 0 18px;
    }
}


</style>