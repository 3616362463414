<template>
    <Dialog 
        class="modal" 
        :style="{
            width: '280px',
            borderRadius: '8px',
        }" 
        :visible="visible" 
        :closable="false"
        @hide="close" 
        @update:visible="onUpdate"
    >
        <template #header>
            <h5 class="m-auto">Nenhum Serviço Ativo</h5>
        </template>

        <div class="content">
            <p class="error">Todos os serviços ativos já foram vinculados a este local.</p>
            <div class="buttons">
                <Button label="Ir até Serviços Oferecidos" @click="CloseAll" />
                <Button class="btn-cancel" label="Cancelar" @click="close" />
            </div>
        </div>
    </Dialog>
</template>

<script>
export default {
    props: ["visible"],
    emits: ["update:visible", "closeall"],
    setup(_, { emit }) {
        const close = () => emit("update:visible", false)
        const onUpdate = val => (!val) && emit("update:visible", val);

        const CloseAll = () => {
            close();
            emit("update:visible", false);
            emit("closeall", true);
        }

        return {
            close,
            onUpdate,
            CloseAll
        };
    },
};
</script>

<style lang="scss" scoped>
.content {
    margin-top: 1.5rem;    
    margin-bottom: -10px;
    p{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        margin: 0 auto;
        color: #828282;
    }
    .error{
        max-width: 290px;
    }
    .buttons{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin-top: 24px;
        .btn-cancel{
            background: white;
            max-width: 104px;
            border: 1px solid white;
            box-shadow: none;
            color: #FF4500;
        }
    }
}

::v-deep(button) {
    max-width: 198px;
    height: 40px;
}
</style>
