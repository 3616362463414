<template>
    <div class="flex flex-column gap-24 mt-16 suggestions">
        <p 
            class="w-max cursor-pointer" 
            :key="suggestion.name" 
            @click="selectService(suggestion)"
            v-for="suggestion in suggestions" 
        >
            {{ suggestion.name }}
        </p>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
// import A from "./A";
import profileService from "../../../../../core/services/professionalProfileService";
import { ListLastsServicesRequest } from "../../../../../core/grpc/generated/professionalProfile_grpc_web_pb";

export default {
    props: ["search", "addressPlaceId"],
    emits: ["update:search", "loaded"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        const suggestions = ref([]);
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };
        const ListLastsServices = async () => {
            try {
                const req = new ListLastsServicesRequest();
                req.setAddressplaceid(props.addressPlaceId);
                const res = await profileService.listLastService(req);
                const { success } = res

                console.log(res);

                if (success) {
                    suggestions.value = res.servicenamesList;
                } else {
                    alert(res.errormessage)
                }
            } catch (error) {
                console.log(error)
            }

        }

        const selectService = (item) => {
            emit("update:search", item)
        }

        onMounted(async () => {
            emit("loaded", false)
            await ListLastsServices();
            emit("loaded", true)
        })


        return {
            suggestions,
            selectService
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

.suggestions {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #FF6A33;
}


</style>