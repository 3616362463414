<template>
  <div>
    <Dialog class="modal" :style="{
      width: '462px',
      borderRadius: '8px',
    }" :visible="visible" @hide="close" @update:visible="onUpdate">
      <template #header>
        <h5 class="m-auto">Editar Local de Atendimento</h5>
      </template>
      <!-- <Loader v-if="isLoading" /> -->
      <div class="content">
        <div class="container-image">
          <img class="img-avatar" :src="avatar" alt="" v-if="avatar" />
          
          <div v-if="!avatar">
            <label for="avatar" class="flex align-items-center txt-add cursor-pointer gap-4" >
              <img src="../../../../../../assets/icons/upload-image.png" alt="" />
              Adicionar foto do local
            </label> 
          </div>

          <div class="flex align-itens-center gap-12" v-else>
            <label for="avatar" class="change-avatar txt-white">
              Alterar imagem
              <img src="../../../../../../assets/icons/myservices/edit-local.png" alt="" />
            </label>
            
            <label class="remove-avatar txt-white gap-8" @click="avatar = ''">
              Remover
              <img src="../../../../../../assets/icons/myservices/delete-local.png" alt="" />
            </label>
          </div>
        </div>
        <input id="avatar" type="file" @change="changeAvatar" accept="image/png, image/jpeg" />

        <PaletteColor v-model:color="color" :edition="true" v-if="loaded" />

        <div class="flex flex-column gap-6 mt-16 inputs">
          <InputText type="text" v-model="name" placeholder="Nome do local" />
          <InputText type="text" v-model="type" placeholder="Tipo (clínica, hospital, laboratório,... )" />
          <Address v-model:fullAddress="address" :edition="true" v-if="loaded" />
        </div>
      </div>

      <template #footer>
        <div class="flex justify-content-end align-items-center h-full">
          <Button label="Salvar" class="addService-button" @click="onSubmit" />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import axios from 'axios';
import Address from "../../../../../../components/Address";
import profileService from "../../../../../core/services/professionalProfileService";
import {
  UpdatePlaceRequest,
} from "../../../../../core/grpc/generated/professionalProfile_pb.js";
import PaletteColor from "../../../../../../components/PaletteColor/PaletteColor.vue";

export default {
  props: ["visible", "obj"],
  emits: ["update:visible", "update:success"],
  components: {
    PaletteColor,
    Address,
  },
  setup(props, ctx) {
    const id = ref(null);
    const avatar = ref(null);
    const loaded = ref(false);
    const color = ref(null);
    const name = ref("");
    const type = ref("");
    const address = ref({ cep: "", state: "", city: "", address: "", number: "", complement: "", neighborhood: "" })
    const close = () => ctx.emit("update:visible", false)

    const onUpdate = (val) => {
      (!val) && ctx.emit("update:visible", val);
    }

    const UpdateLocation = async obj => {
      try {
        console.log('update', obj)
        const req = new UpdatePlaceRequest();
        req.setId(obj.id);
        req.setColor(obj.color);
        req.setName(obj.name);
        req.setType(obj.type);
        req.setUrl(obj.url);
        req.setAddress(obj.address);
        req.setNumber(obj.number);
        req.setComplement(obj.complement);
        req.setNeighborhood(obj.neighborhood);
        req.setCity(obj.city);
        req.setState(obj.state);
        req.setPostalcode(obj.postalcode);
        const res = await profileService.updateLocation(req);

        console.log(res);

        if(res.success){
          ctx.emit("update:success", true);
          close();
        }

        console.log(res);        
      } catch (error) {
        
      }
    }

    const onSubmit = async () => {
      const obj = await {
        id: id.value,
        color: color.value,
        name: name.value,
        type: type.value,
        url: avatar.value ? avatar.value.replace(process.env.VUE_APP_BUCKET_URL + '/', '') : null,
        address: address.value.address,
        number: address.value.number,
        complement: address.value.complement,
        neighborhood: address.value.neighborhood,
        city: address.value.city,
        state: address.value.state,
        postalcode: address.value.cep,
      }

      await UpdateLocation(obj);
    }

    const changeAvatar = (e) => {
      const token = window.localStorage.getItem("token");
      const file = e.target.files[0];

      const formData = new FormData()
      formData.append('file', file)

      const bearer = {
        'content-type': 'multipart/form-data',
        headers: {
          Authorization: "Bearer " + token,
        }
      };

      axios.post(`${process.env.VUE_APP_FILE_API_URL}/professional/location_image`, formData, bearer)
        .then(function (response) {
          console.log(response)
          const path = process.env.VUE_APP_BUCKET_URL + '/' + response.data.url
          avatar.value = path
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    
    onMounted(async () => {
      console.log(props.obj);
      if(props.obj.pictureUrl){
        avatar.value = await process.env.VUE_APP_BUCKET_URL + '/' + props.obj.pictureUrl;
      }
      id.value = await props.obj.id;
      name.value = await props.obj.name;
      type.value = await props.obj.type;
      color.value = await props.obj.color;
      address.value = await {
        cep: props.obj.postalCode,
        state: props.obj.state,
        city: props.obj.city,
        address: props.obj.address,
        number: props.obj.number,
        complement: props.obj.complement,
        neighborhood: props.obj.neighborhood,
      }
      loaded.value = await true;
    })

    return {
      color,
      name,
      type,
      address,
      close,
      onUpdate,
      onSubmit,
      loaded,
      avatar,
      changeAvatar,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-top: 1.5rem;

  .txt-add {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #2F80ED;
    z-index: 10;
  }

  .txt-white{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: white;
  }

  .change-avatar {
    background: #cad1da;
    background: -webkit-linear-gradient(to right, #cad1da, #b8bfb5, #aabcac);
    background: linear-gradient(to right, #cad1da, #b8bfb5, #aabcac);
    z-index: 10;
    border-radius: 100px;
    height: 28px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    gap: 8px;
    cursor: pointer;
  }

  .remove-avatar {
    background: #e3c8c0;
    background: -webkit-linear-gradient(to right, #e3c8c0, #d9c5b9, #cbc7b4);
    background: linear-gradient(to right, #e3c8c0, #d9c5b9, #cbc7b4);
    z-index: 10;
    border-radius: 100px;
    height: 28px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    cursor: pointer;
  }


  p,
  .locais {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #BDBDBD;
  }
}

.container-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  border-radius: 8px;
  border: 1px solid #F2F2F2;
}

.img-avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  z-index: 1;
  object-fit: cover;
}

input[type='file'] {
  display: none;
}

::v-deep(input) {
  border: 1px solid #F2F2F2;
  border-radius: 8px;
  height: 45px;

  &::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #828282;
  }
}
</style>
